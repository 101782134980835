<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="8" lg="6" xl="6">
        <v-btn :to="`/${organizationUrl}`" color="primary" plain>
          <v-icon left>mdi-arrow-left</v-icon>Revenir au planning
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" xs="12" sm="12" md="10" lg="8" xl="6">
        <v-card class="elevation-12">
          <v-toolbar color="deep-orange lighten-3" dark flat>
            <v-toolbar-title>{{
              $helpers.string.capitalizeI18N("enter-credentials")
            }}</v-toolbar-title>
            <v-spacer />
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-information</v-icon>
              </template>
              <span>Entrez vos informations de connexion pour accéder à vos
                données.</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <v-alert v-if="$route.query.resetpassword" type="success">{{
              $t("reset-password-confirmation")
            }}</v-alert>
            <v-alert v-if="message" type="success">{{ message }}</v-alert>
            <v-form ref="form">
              <v-container>
                <v-row>
                  <v-text-field :label="'e-mail ou code client *'" v-model="client.login" name="email"
                    prepend-icon="mdi-email" type="email" :rules="$helpers.rule.notEmpty()" />
                </v-row>
                <v-row>
                  <v-text-field v-model="client.password" id="password" :label="$helpers.string.capitalizeI18N('password', {
                    required: true,
                  })
                    " name="password" prepend-icon="mdi-lock" type="password" @keydown.enter="enterPressed()"
                    :rules="$helpers.rule.notEmpty()" />
                </v-row>
                <v-row class="mt-8">
                  <v-btn color="deep-orange lighten-3" dark @click="handleLogin" :disabled="loading" block>
                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                    <span>{{ $t("connection") }}</span>
                  </v-btn>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-container>
              <v-row no-gutters>
                <v-col>
                  <div>
                    <v-btn text :to="getLink('reset-password')" color="primary lighten-1">{{ $t("forgot-password")
                    }}</v-btn>
                  </div>
                  <div>
                    <v-btn class="font-weight-medium" text :to="getLink('register')" color="primary">{{
                      $t("no-account-register") }}</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "OrganizationLogin",
  data() {
    return {
      client: {
        login: "",
        password: "",
      },
      message: null,
      loading: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.publicorganizationauth.client;
    },
    organizationUrl() {
      return this.$route.params.organizationUrl;
    },
  },
  created() {
    if (this.loggedIn) {
      console.dir("is logged !");
      this.$helpers.router.push("/" + this.organizationUrl);
    }
  },
  methods: {
    getLink(target) {
      return this.$helpers.router.getOrganizationLink(target);
    },
    enterPressed() {
      this.handleLogin();
    },
    handleLogin() {
      // this.$validator.validateAll().then(isValid => {
      //   if (!isValid) {
      //     this.loading = false;
      //     return;
      //   }
      if (!this.$refs.form.validate()) return;

      if (this.client.login && this.client.password) {
        this.loading = true;

        this.$store.dispatch("publicorganizationauth/login", this.client).then(
          () => {
            if (this.$route.query.next) {
              this.$router.push(this.$route.query.next);
            } else {
              this.$router.push("/" + this.organizationUrl);
            }
          },
          (error) => {
            this.loading = false;

            switch (error.response?.data?.code) {
              case "POL.password-undefined":
                this.message = this.$t("errors." + error.response?.data?.code);
                break;
              default:
                this.$helpers.snackbar.handleError(error);
            }
          }
        );
      }
      //});
    },
  },
};
</script>